import React from "react"
import Layout from "../components/layout"

const NotFound = () => {
	return (
		<Layout location={'404'}>
			<div className={"container"}>
				<h1>Error 404: we are lost together</h1>
				<a href="/">
					Go back home
				</a>
				
			</div>
		</Layout>
	)
}

export default NotFound;